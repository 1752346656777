import * as api from "../../api";

import { FETCH_ALLBLOG, DELETEBLOG, UPDATEBLOG, CREATEBLOG } from "../constants/actionTypes.js";

export const getBlogs = () => async (dispatch) => {
    try {
        const { data } = await api.fetchBlogs();
        dispatch({ type: FETCH_ALLBLOG, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const createBlog = (post) => async (dispatch) => {
    try {
        const { data } = await api.createBlog(post);

        dispatch({ type: CREATEBLOG, payload: data });
    } catch (error) {
        alert('Ohpps, unable to create new BLOG due to an error.')
        console.log(error)
    }
}

export const updateBlog = (id, post) => async (dispatch) => {
    try {
        const { data } = await api.updateBlog(id, post);
        dispatch({ type: UPDATEBLOG, payload: data });
    } catch (error) {
        console.log(error);
        alert('Ohpps, unable to create new BLOG due to an error.')
    }
}

export const deleteBlog = (id) => async (dispatch) => {
    try {
        await api.deleteBlog(id);
        dispatch({ type: DELETEBLOG, payload: id });
        alert("BLOG deleted successfully. Refresh your browser to view")
    } catch (error) {
        alert("0hpps, Unable to delete BLOG")
        console.log(error)
    }
}