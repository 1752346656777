import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { createBOOK } from '../redux/actions/gwopResources';

function BookForm() {
    const [values, setValues] = useState({
        title: '',
        image: '',
        author: '',
        content: "",
        link: "",
    })

    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const clearBookDetail = () => {
        setValues({
            title: "",
            image: "",
            author: "",
            content: "",
            link: "",
        })
    }

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(false)
        setLoading(true)
        const success = dispatch(createBOOK(values, setLoading, clearBookDetail, setMessage))
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        if (!success) {
            setLoading(false)
            setMessage('failed')

        } else {
            setLoading(false)
            setMessage('success')
            clearBookDetail()
        }

    }

    return (
        <div>
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>Add GWOP Book Resources</h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    {message === "success" && <div className='message'>Form submitted successfully</div>}
                                    {message === "failed" && <div className='message'>Ohpps!!!, Form not submitted</div>}
                                    <form onSubmit={handleSubmit} >
                                        <div className='input-div'>
                                            <label >Book Title</label>
                                            <input type="text" placeholder="Book Title ..." name="title" value={values.title} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label >Book Author</label>
                                            <input type="text" placeholder="Book Author ..." name="author" value={values.author} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Book Description</label>
                                            <textarea placeholder="Short description about the book..." name="content" value={values.content} className='textareafield' required rows="4" onChange={handleChange} ></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label>Choose an image  <span style={{ fontSize: '12px', textTransform: 'capitalize' }}>(Let image width to be 320px - 450px and height 300px -350px)</span></label>
                                            <input type="url" placeholder="image" name="image" className='inputfield' value={values.image} required onChange={handleChange} />
                                        </div>
                                        <div className='input-div'>
                                            <label>Book link (The live url where the book go to)</label>
                                            <input type="url" placeholder="book link" name="link" className='inputfield' value={values.link} required onChange={handleChange} />
                                        </div>
                                        <div className='button-div'>
                                            <button onClick={clearBookDetail} className="submitButton clear">Clear</button>

                                            {loading ? <button type="submit" className='submitButton disabled'>Submitting ...</button> :
                                                <button type="submit" className='submitButton'>Submit</button>
                                            }


                                        </div>

                                        <div style={{ padding: '20px 0px' }}>
                                            {message === "success" && <div className='message'>Form submitted successfully</div>}
                                            {message === "failed" && <div className='message'>Ohpps!!!, Form not submitted</div>}
                                        </div>

                                    </form>
                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookForm