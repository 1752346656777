import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { createGes, updateGes } from "../redux/actions/gesaction";
import "./styles.css";


function Gesform({ currentId, setCurrentId }) {
    const post = useSelector((state) => currentId ? state.gesReducer.find((p) => p._id === currentId) : null);

    const [values, setValues] = useState({
        testimony: "",
        image: "",
        name: ""
    })

    useEffect(() => {
        if (post) setValues(post)
    }, [post])


    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (currentId) {
            dispatch(updateGes(currentId, values));
            alert("Post updated successfully. Refresh your browser to view post")
            clearges()
        } else {
            dispatch(createGes(values));
            console.log("here is it", values)
            alert("Post created successfully. Refresh your browser to view post")
            clearges()
        }

    }

    const clearges = () => {
        setCurrentId(null);
        setValues({
            testimony: "",
            image: "",
            name: ""

        })
    }
    return (
        <div>
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>{currentId ? "Editing GES Feedback" : "Add GES Feedback"}</h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    <form onSubmit={handleSubmit} >
                                        <div className='input-div'>
                                            <label >Name</label>
                                            <input type="text" placeholder="Name of testifier" name="name" value={values.name} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Your testimony</label>
                                            <textarea placeholder="Write your testimony" value={values.testimony} className='textareafield' required name="testimony" rows="4" onChange={handleChange} ></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label>Choose an image</label>
                                            <input type="url" placeholder="image" name="image" className='inputfield' value={values.image} required onChange={handleChange} />
                                        </div>
                                        <div className='button-div'>
                                            <button onClick={clearges} className="submitButton clear">Clear</button>
                                            <button type="submit" className='submitButton'>Submit</button>

                                        </div>

                                    </form>
                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gesform