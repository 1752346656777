import React, { useEffect } from 'react';
import moment from "moment"
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, deleteBlog } from "../redux/actions/blogaction";

function BlogView({ currentId, setCurrentId }) {
    const dispatch = useDispatch();

    const posts = useSelector((state) => state.blogReducer)
    console.log(posts)

    useEffect(() => {
        dispatch(getBlogs())
    }, [currentId, dispatch])


    return (
        <div>
            {!posts.length ? <div className="loading" style={{ paddingBottom: "30px" }}></div> : (
                <div className='container containerWrapper'>
                    <div className='title'>Joy Agunbiade Blogs</div>
                    <div className='bookContainer'>
                        {posts.sort((a, b) => moment(new Date(b.id)) - moment(new Date(a.id))).map((post, i) => (
                            <div className='cardWrapper' key={i}>
                                <div className='image'>
                                    <img src={post.image} />
                                </div>

                                <div className='wrapper'>
                                    <div className='name'>{post.title}</div>
                                    <div className='testimony'>{post.excerpt} ...</div>
                                    <div className='action'>
                                        <div className='del-btn'><span onClick={() => dispatch(deleteBlog(post._id))} className="delete-btn">Delete</span></div>
                                        <NavLink to="/add_blog">
                                            <button onClick={() => setCurrentId(post._id)} className="edit">Edit</button>
                                        </NavLink>

                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>



            )}

        </div>
    )
}

export default BlogView