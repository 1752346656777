import React, { useState, useEffect } from 'react';
import "./gwop.css";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { createGwop, updateGwop } from "../redux/actions/gwopaction";


function Gwop({ currentId, setCurrentId }) {
    const post = useSelector((state) => currentId ? state.gwopReducer.find((p) => p._id === currentId) : null);

    const [values, setValues] = useState({
        testimony: "",
        image: "",
        name: "",
        location: "",
    })

    useEffect(() => {
        if (post) setValues(post)
    }, [post])


    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (currentId) {
            dispatch(updateGwop(currentId, values));
            alert("Post updated successfully. Refresh your browser to view post")
            cleargwop()
        } else {
            dispatch(createGwop(values));
            console.log("here is it", values)
            alert("Post created successfully. Refresh your browser to view post")
            cleargwop()
        }

    }

    const cleargwop = () => {
        setCurrentId(null);
        setValues({
            testimony: "",
            location: "",
            image: "",
            name: ""

        })
    }

    return (
        <div>
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>{currentId ? "Editing GWOP Feedback" : "Add GWOP Feedback"}</h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    <form onSubmit={handleSubmit} >
                                        <div className='input-div'>
                                            <label >Name</label>
                                            <input type="text" placeholder="Name of testifier" name="name" className='inputfield' value={values.name} required onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Your testimony</label>
                                            <textarea placeholder="Write your testimony" className='textareafield' name="testimony" rows="4" value={values.testimony} required onChange={handleChange}></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label >Location</label>
                                            <input type="text" placeholder="Your location" name="location" className='inputfield' value={values.location} required onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Choose an image</label>
                                            <input type="url" placeholder="image" name="image" className='inputfield' value={values.image} required onChange={handleChange} />

                                        </div>

                                        <div className='button-div'>
                                            <button onClick={cleargwop} className="submitButton clear">Clear</button>
                                            <button type="submit" className='submitButton'>Submit</button>
                                        </div>

                                    </form>
                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gwop