import { FETCH_ALLGWOP, DELETEGWOP, UPDATEGWOP,  CREATEGWOP} from "../constants/actionTypes.js";

export const gwopReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALLGWOP:
            return action.payload;
        case CREATEGWOP:
            return [...state, action.payload];
        case UPDATEGWOP:
            return state.map((post) => post._id === action.payload._id ? action.payload : post);
        case DELETEGWOP:
            return state.filter((post) => post._id  !== action.payload)
            default:
            return state;
    }
}