import * as api from "../../api";
import { FETCH_ALLEvent, DELETEEvent, UPDATEEvent, CREATEEvent } from "../constants/actionTypes.js";

export const fetchEvent = () => async (dispatch) => {
    try {
        const { data } = await api.fetchEvent();
        dispatch({ type: FETCH_ALLEvent, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const createEvent = (post) => async (dispatch) => {
    try {
        const { data } = await api.createEvent(post);
        dispatch({ type: CREATEEvent, payload: data })
    } catch (error) {
        alert('Ohpps, unable to create new event due to an error.')
        console.log(error)
    }
}

export const updateEvent = (id, post) => async (dispatch) => {
    try {
        const { data } = await api.updateEvent(id, post);
        dispatch({ type: UPDATEEvent, payload: data });
    } catch (error) {
        console.log(error);
        alert('Ohpps, unable to create new event due to an error.')
    }
}

export const deleteEvent = (id) => async (dispatch) => {
    try {
        await api.deleteEvent(id);
        dispatch({ type: DELETEEvent, payload: id });
        alert("Event deleted successfully. Refresh your browser to view")
    } catch (error) {
        alert("0hpps, Unable to delete Event")
        console.log(error)
    }
}