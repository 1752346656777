import * as api from "../../api";

import { FETCH_ALLGWOP, DELETEGWOP, UPDATEGWOP,  CREATEGWOP} from "../constants/actionTypes.js";

export const getGwops = () => async(dispatch) => {
    try {
        const {data} = await api.fetchGwops();
        dispatch({type: FETCH_ALLGWOP, payload: data})
    } catch (error) {
        console.log(error.message)
    }
}

export const createGwop = (post) => async  (dispatch) => {
    try {
        const { data } = await api.createGwop(post);

        dispatch({ type: CREATEGWOP, payload: data });
    } catch (error) {
        alert('Ohpps, unable to create new post due to an error.')
        console.log(error)
    }
}

export const updateGwop = (id, post) => async(dispatch) => {
    try {
        const {data} = await api.updateGwop(id, post);
        dispatch({ type: UPDATEGWOP, payload: data});
    } catch (error) {
        console.log(error);
        alert('Ohpps, unable to create new post due to an error.')
    }
}

export const deleteGwop = (id) => async(dispatch) => {
    try {
        await api.deleteGwop(id);
        dispatch({ type: DELETEGWOP, payload: id});
        alert("Post deleted successfully. Refresh your browser to view")
    } catch (error) {
        alert("0hpps, Unable to delete Post")
        console.log(error)
    }
}