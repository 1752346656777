import React, { useEffect } from 'react';
import moment from "moment"
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchEvent, deleteEvent } from "../redux/actions/eventaction";

function EventView({ currentId, setCurrentId }) {
    const dispatch = useDispatch();

    const posts = useSelector((state) => state.eventReducer)
    console.log(posts)

    useEffect(() => {
        dispatch(fetchEvent())
    }, [currentId, dispatch])


    return (
        <div>
            {!posts.length ? (
                posts.length == 0 ? <div><h1 style={{ textAlign: "center", paddingTop: "15%" }}>No Event yet</h1> </div> :
                    <div className="loading" style={{ paddingBottom: "30px" }}></div>) :
                (
                    <div className='post-content container'>
                        <div className='title'>Joy Event </div>
                        <div className='row gx-5'>
                            {posts.sort((a, b) => moment(new Date(b.startdate)) - moment(new Date(a.startdate))).map((post, i) => (
                                <div className='col-lg-3 col-md-4 col-sm-6' key={i}>
                                    <div className='card'>

                                        <div className='name'>{post.title}</div>
                                        <div className='location'>{post.location} </div>
                                        <div className='location'>{post.startdate} </div>

                                        <div className='action'>
                                            <div className='del-btn'><span onClick={() => dispatch(deleteEvent(post._id))} className="delete-btn">Delete</span></div>
                                            <NavLink to="/add_event">
                                                <button onClick={() => setCurrentId(post._id)} className="edit">Edit</button>
                                            </NavLink>

                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>



                )}

        </div>
    )
}

export default EventView