import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { updateBOOK } from '../redux/actions/gwopResources';

function EditGwopBookForm({ popupcontent, setToggleState, message, setMessage }) {

    console.log("popupcontent", popupcontent)

    const [values, setValues] = useState({
        title: popupcontent?.title,
        image: popupcontent.image,
        author: popupcontent.author,
        content: popupcontent.content,
        link: popupcontent.link,
    })

    console.log("Values", values)
    useEffect(() => { setValues(popupcontent) }, [popupcontent])

    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const clearBookDetail = () => {
        setValues({
            title: "",
            image: "",
            author: "",
            content: "",
            link: "",
        })
    }

    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            title: values.title,
            image: values.image,
            author: values.author,
            content: values.content,
            link: values.link,
        }
        const id = popupcontent?._id
        setMessage(false)
        setLoading(true)
        dispatch(updateBOOK(id, payload, setLoading, setMessage, clearBookDetail, setToggleState));
    }

    const goBack = () => {
        setToggleState(1);
    }
    return (
        <div>
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>Editing GWOP Book Resources
                            <span onClick={goBack}>Back</span>
                        </h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    {message === "success" && <div className='message'>Form editted successfully</div>}
                                    {message === "failed" && <div className='message'>Ohpps!!!, Form not editted</div>}
                                    <form onSubmit={handleSubmit} >
                                        <div className='input-div'>
                                            <label >Book Title</label>
                                            <input type="text" placeholder="Book Title ..." name="title" value={values.title} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label >Book Author</label>
                                            <input type="text" placeholder="Book Author ..." name="author" value={values.author} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Book Description</label>
                                            <textarea placeholder="Short description about the book..." name="content" value={values.content} className='textareafield' required rows="4" onChange={handleChange} ></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label>Choose an image <span style={{ fontSize: '12px', textTransform: 'capitalize' }}>(Let image width to be 320px - 450px and height 300px -350px)</span></label>
                                            <input type="url" placeholder="image" name="image" className='inputfield' value={values.image} required onChange={handleChange} />
                                        </div>
                                        <div className='input-div'>
                                            <label>Book link (The live url where the book go to)</label>
                                            <input type="url" placeholder="book link" name="link" className='inputfield' value={values.link} required onChange={handleChange} />
                                        </div>
                                        <div className='button-div'>
                                            <button onClick={clearBookDetail} className="submitButton clear">Clear</button>

                                            {loading ? <button type="submit" className='submitButton disabled'>Submitting ...</button> :
                                                <button type="submit" className='submitButton'>Submit</button>
                                            }


                                        </div>

                                    </form>
                                    <div style={{ padding: '20px 5px' }}>
                                        {message === "success" && <div className='message'>Form editted successfully</div>}
                                        {message === "failed" && <div className='message'>Ohpps!!!, Form not editted</div>}
                                    </div>

                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditGwopBookForm