import { CREATEGWOPRESOURCES, DELETEGWOPRESOURCES, FETCHGWOPRESOURCES, FETCHSINGLEGWOPRESOURCES, UPDATEGWOPRESOURCES } from "../constants/actionTypes";

export const gwopResourcesReducer = (state = [], action) => {
    switch (action.type) {
        case FETCHGWOPRESOURCES:
            return action.payload;
        case FETCHSINGLEGWOPRESOURCES:
            return action.payload;
        case CREATEGWOPRESOURCES:
            return [...state, action.payload];

        case UPDATEGWOPRESOURCES:
            return state.map((post) => post._id === action.payload._id ? action.payload : post);
        case DELETEGWOPRESOURCES:
            return state.filter((post) => post._id !== action.payload)
        default:
            return state;
    }
}