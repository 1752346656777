import * as api from '../../api/index.js';
import { CREATEGWOPRESOURCES, DELETEGWOPRESOURCES, END_LOADING, FETCHGWOPRESOURCES, FETCHSINGLEGWOPRESOURCES, START_LOADING, UPDATEGWOPRESOURCES } from '../constants/actionTypes.js';


export const getAllBOOKS = () => async (dispatch) => {
    try {
        const { data } = await api.fetchBooks();
        dispatch({ type: FETCHGWOPRESOURCES, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const createBOOK = (values, setLoading, clearBookDetail, setMessage) => async (dispatch) => {
    try {
        setMessage(false)
        const data = await api.createBook(values);
        dispatch({ type: CREATEGWOPRESOURCES, payload: data });
        setLoading(false);
        setMessage('success')
        clearBookDetail()
        setMessage('success')

    } catch (error) {
        setLoading(false);
        setMessage('failed')
        console.log(error)
    }
}

export const updateBOOK = (id, payload, setLoading, setMessage, clearBookDetail, setToggleState) => async (dispatch) => {
    try {
        const { data } = await api.updateBook(id, payload);
        dispatch({ type: UPDATEGWOPRESOURCES, payload: data });
        setLoading(false);
        setMessage('success')
        clearBookDetail()
    } catch (error) {
        console.log(error);
        setLoading(false);
        setMessage('failed')
        // alert('Ohpps, unable to create new post due to an error.')
    }
}

export const deleteBOOK = (id, setalerttoggle) => async (dispatch) => {
    try {
        await api.deleteBook(id);
        dispatch({ type: DELETEGWOPRESOURCES, payload: id });
        alert("Book deleted successfully. Refresh your browser to view")
        setalerttoggle(false)
    } catch (error) {
        alert("0hpps, Unable to delete Book")
        console.log(error)
    }
}