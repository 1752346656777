import React, { useEffect } from 'react';
import "./gwop.css"
import moment from "moment"
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getGwops, deleteGwop } from "../redux/actions/gwopaction";

function Managegwop({ currentId, setCurrentId }) {
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.gwopReducer)
  console.log(posts)

  useEffect(() => {
    dispatch(getGwops())
  }, [currentId, dispatch])

  return (
    <div>
      {!posts.length ? <div className="loading" style={{ paddingBottom: "30px" }}></div> : (
        <div className='container containerWrapper'>
          <div className='title'>GWOP Feedback </div>
          <div className='bookContainer'>
            {posts.sort((a, b) => moment(new Date(b.id)) - moment(new Date(a.id))).map((post, i) => (
              <div className='cardWrapper' key={i}>
                <div className='image'>
                  <img src={post.image} alt="" />
                </div>

                <div className='wrapper'>
                  <div className='name'>{post.name}</div>
                  <div className='location'>{post.location} </div>

                  <div className='testimony'>{post.testimony}</div>

                  <div className='action'>
                    <div className='del-btn'><span onClick={() => dispatch(deleteGwop(post._id))} className="delete-btn">Delete</span></div>
                    <NavLink to="/gwop_form" style={{ textDecoration: 'none' }}>
                      <button onClick={() => setCurrentId(post._id)} className="edit">Edit</button>
                    </NavLink>

                  </div>
                </div>
              </div>
            ))}

          </div>

        </div>
      )}
    </div>
  )
}

export default Managegwop