import axios from 'axios';

const gesurl = "https://joyagunbiadeserver.onrender.com/gesfeedback";

export const fetchGes = () => axios.get(gesurl);
export const createGes = (newPost) => axios.post(gesurl, newPost);
export const updateGes = (id, gesPost) => axios.patch(`${gesurl}/${id}`, gesPost);
export const deleteGes = (id) => axios.delete(`${gesurl}/${id}`);



//ges url
const gwopurl = "https://joyagunbiadeserver.onrender.com/gwopfeedback"

export const fetchGwops = () => axios.get(gwopurl);
export const createGwop = (newPost) => axios.post(gwopurl, newPost);
export const updateGwop = (id, updatedPost) => axios.patch(`${gwopurl}/${id}`, updatedPost);
export const deleteGwop = (id) => axios.delete(`${gwopurl}/${id}`);

//joy agunbiade blog
const blogurl = "https://joyagunbiadeserver.onrender.com/blog"
export const fetchBlogs = () => axios.get(blogurl);
export const createBlog = (newPost) => axios.post(blogurl, newPost);
export const updateBlog = (id, updatedPost) => axios.patch(`${blogurl}/${id}`, updatedPost);
export const deleteBlog = (id) => axios.delete(`${blogurl}/${id}`);


//joy agunbiade event
const eventurl = "https://joyagunbiadeserver.onrender.com/event"

export const fetchEvent = () => axios.get(eventurl);
export const createEvent = (newPost) => axios.post(eventurl, newPost);
export const updateEvent = (id, updatedEvent) => axios.patch(`${eventurl}/${id}`, updatedEvent);
export const deleteEvent = (id) => axios.delete(`${eventurl}/${id}`);

// gwop book resources
const RESOURCESAPI = 'https://joyagunbiadeserver.onrender.com/gwop_resources'
export const fetchBooks = () => axios.get(RESOURCESAPI);
export const fetchSingleBook = (title) => axios.get(`${RESOURCESAPI}/${title}`);
export const createBook = (newBook) => axios.post(RESOURCESAPI, newBook);
export const updateBook = (id, updatedBook) => axios.patch(`${RESOURCESAPI}/${id}`, updatedBook)
export const deleteBook = (id) => axios.delete(`${RESOURCESAPI}/${id}`);




// export  const signIn = (values) => API.post('/user/signin', values)
// export  const signUp = (values) => API.post('/user/signup', values)