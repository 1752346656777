import React, { useState, useEffect } from 'react';
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { createBlog, updateBlog } from "../redux/actions/blogaction";


function BlogForm({ currentId, setCurrentId }) {
    const post = useSelector((state) => currentId ? state.blogReducer.find((p) => p._id === currentId) : null);

    const [values, setValues] = useState({
        number: "",
        title: "",
        podcast: "",
        image: "",
        content: "",
        excerpt: "",
        publisher: ""
    })

    useEffect(() => {
        if (post) setValues(post)
    }, [post])


    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (currentId) {
            dispatch(updateBlog(currentId, values));
            alert("Post updated successfully. Refresh your browser to view post")
            clearBlog()
        } else {
            dispatch(createBlog(values));
            console.log("here is it", values)
            alert("Post created successfully. Refresh your browser to view post")
            clearBlog()
        }

    }

    const clearBlog = () => {
        setCurrentId(null);
        setValues({
            number: "",
            title: "",
            podcast: "",
            image: "",
            content: "",
            excerpt: "",
            publisher: ""

        })
    }

    return (
        <div>
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>{currentId ? "Editing Joy Agunbiade Blog" : "Add Blog to Joy Agunbiade website"}</h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    <form onSubmit={handleSubmit} >
                                        {/* <div className='input-div'>
                                            <label >Serial Number(take note of the number you give to the previous blog)</label>
                                            <input type="number" min="1" placeholder="give a unique number to each blog post" name="number" className='inputfield' value={values.number} required onChange={handleChange} />
                                        </div> */}

                                        <div className='input-div'>
                                            <label >Blog Title</label>
                                            <input type="text" placeholder="Blog title" name="title" className='inputfield' value={values.title} required onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label >Blog Podcast</label>
                                            <input type="url" placeholder="Podcast pertaining to blog(if any)" name="podcast" className='inputfield' value={values.podcast} onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Choose an image</label>
                                            <input type="url" placeholder="image" name="image" className='inputfield' value={values.image} required onChange={handleChange} />

                                        </div>

                                        <div className='input-div'>
                                            <label>Blog Content</label>
                                            <textarea placeholder="Blog content here..." className='textareafield' name="content" rows="5" value={values.content} required onChange={handleChange}></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label>Excerpt(copy and cut one or two line from the content above)</label>
                                            <textarea maxlength="120" placeholder="From the blog content above, cut and paste one or two lines here" className='textareafield' name="excerpt" rows="2" value={values.excerpt} required onChange={handleChange}></textarea>
                                        </div>

                                        <div className='input-div'>
                                            <label >Publisher Name</label>
                                            <input type="text" placeholder="Publisher name" name="publisher" className='inputfield' value={values.publisher} required onChange={handleChange} />
                                        </div>

                                        <div className='button-div'>
                                            <button onClick={clearBlog} className="submitButton clear">Clear</button>
                                            <button type="submit" className='submitButton'>Submit</button>
                                        </div>

                                    </form>
                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogForm