import * as api from "../../api";
import { FETCH_ALL, DELETE, UPDATE,  CREATE} from "../constants/actionTypes.js";

export const fetchGes = () =>async (dispatch) => {
    try {
        const {data} = await api.fetchGes();
        dispatch({type: FETCH_ALL, payload: data})
    } catch (error) {
        console.log(error.message)
    }
}

export const createGes = ( post ) => async  (dispatch) => {
    try {
        const { data } = await api.createGes(post);
        dispatch({ type: CREATE, payload: data })
    } catch (error) {
        alert('Ohpps, unable to create new post due to an error.')
        console.log(error)
    }
}


export const updateGes= (id, post) => async(dispatch) => {
    try {
        const {data} = await api.updateGes(id, post);
        dispatch({ type: UPDATE, payload: data});
    } catch (error) {
        console.log(error);
        alert('Ohpps, unable to create new post due to an error.')
    }
}

export const deleteGes = (id) => async(dispatch) => {
    try {
        await api.deleteGes(id);
        dispatch({ type: DELETE, payload: id});
        alert("Post deleted successfully. Refresh your browser to view")
    } catch (error) {
        alert("0hpps, Unable to delete Post")
        console.log(error)
    }
}