import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import BlogForm from '../blog/blogform.js';
import BlogView from '../blog/blogview.js';

import Dashboard from '../Dashboard/Dashboard.js';
import EventForm from '../Event/eventform.js';
import EventView from '../Event/eventview.js';
import Gesform from '../gesfeedback/gesform.js';
import Manageges from '../gesfeedback/manageges.js';
import Gwop from '../gwopfeedback/gwop.js';
import Managegwop from '../gwopfeedback/managegwop.js';
import BookForm from '../BookResources/BookForm.js';
import ManageBookResources from '../BookResources/ManageBook.js';

// import ProductDetails from '../LearnRedux/container/ProductDetails.js';
// import ProductListing from '../LearnRedux/container/ProductListing.js';

const Router = () => {
    const [currentId, setCurrentId] = useState(null);

    return (
        <Switch>
            {/* pages */}
            <Route exact path='/' component={Dashboard} />
            <Route exact path="/ges_form" component={() => (<Gesform currentId={currentId} setCurrentId={setCurrentId} />)} />
            <Route exact path="/ges_testimony" component={() => (<Manageges currentId={currentId} setCurrentId={setCurrentId} />)} />
            <Route exact path="/gwop_form" component={() => (<Gwop currentId={currentId} setCurrentId={setCurrentId} />)} />

            <Route exact path="/gwop_feedback" component={() => (<Managegwop currentId={currentId} setCurrentId={setCurrentId} />)} />

            <Route exact path="/add_blog" component={() => (<BlogForm currentId={currentId} setCurrentId={setCurrentId} />)} />
            <Route exact path="/blogs" component={() => (<BlogView currentId={currentId} setCurrentId={setCurrentId} />)} />


            <Route exact path="/add_event" component={() => (<EventForm currentId={currentId} setCurrentId={setCurrentId} />)} />
            <Route exact path="/events" component={() => (<EventView currentId={currentId} setCurrentId={setCurrentId} />)} />

            <Route exact path="/resources_form" component={() => (<BookForm />)} />
            <Route exact path="/resources_feedback" component={() => (<ManageBookResources />)} />

            {/* <Route exact path='/' component={ProductListing} />
        <Route exact path='/product/:Id' component={ProductDetails} /> */}
            <Route> 404 not found </Route>

        </Switch>
    )
}
export default Router