import React, { useState, useEffect } from 'react';
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";
import { deleteBOOK, getAllBOOKS } from '../redux/actions/gwopResources';
import './book.css'
import EditGwopBookForm from './editGwopForm';

function ManageBookResources() {
    const dispatch = useDispatch();
    const [toggleState, setToggleState] = useState(1);

    const [message, setMessage] = useState(false)
    const allResources = useSelector((state) => state.gwopResourcesReducer)
    console.log(allResources)

    const [popupcontent, setPopupcontent] = useState({})
    const [alerttoggle, setalerttoggle] = useState(false)

    const changeContent = (data) => {
        setPopupcontent(data);
        setalerttoggle(true)
    }
    const editContent = (data) => {
        setPopupcontent(data);
        setToggleState(2)
    }

    useEffect(() => {
        dispatch(getAllBOOKS())
    }, [dispatch])


    return (
        <div>
            <div className={toggleState === 1 ? "tabContent active-tabContent" : "tabContent"}>
                {!allResources.length ? <div className="loading" style={{ paddingBottom: "30px" }}></div>
                    :
                    <div>
                        {allResources?.length > 0 ? <>
                            <div className=' container containerWrapper'>
                                <div className='title'>GWOP Book Resources </div>
                                <div className='bookContainer'>
                                    {allResources?.sort((a, b) => moment(new Date(b.id)) - moment(new Date(a.id))).map((post, i) => (
                                        <div className='cardWrapper' key={i}>
                                            <div className='image'>
                                                <img src={post.image} alt="" />
                                            </div>
                                            <div className='wrapper'>
                                                <div className='name'>{post.title}</div>
                                                <div className='author'>author: <span>{post.author}</span>  Date: <span>{moment(post.createdAt).format('DD/ MMM /YYYY')}</span></div>

                                                <div className='postcontent'>
                                                    {post.content}
                                                </div>
                                                <div className='action'>
                                                    <div className='del-btn'><span onClick={() => changeContent(post, i)} className="delete-btn">Delete</span></div>
                                                    <div onClick={() => setToggleState(2)}>
                                                        <button onClick={() => editContent(post, i)} className="edit">Edit</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}

                                </div>

                            </div>
                            {alerttoggle && (
                                <div className='popupContainer'>
                                    <div className='formpopupBody' onClick={(e) => e.stopPropagation()}>
                                        <div className='name'>Are you sure you want to delete {popupcontent?.title} ?</div>
                                        <div className='action'>
                                            <button onClick={() => setalerttoggle(false)}>No</button>
                                            <button className='yes' onClick={() => {
                                                dispatch(deleteBOOK(popupcontent._id, setalerttoggle))
                                            }
                                            }>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </> : <>
                            <div className='noData'>No Data</div>
                        </>
                        }
                    </div>

                }

            </div>

            <div className={toggleState === 2 ? "tabContent active-tabContent" : "tabContent"}>
                {popupcontent && <EditGwopBookForm popupcontent={popupcontent}
                    setToggleState={setToggleState}
                    message={message}
                    setMessage={setMessage}
                />}
            </div>
        </div>
    )
}

export default ManageBookResources