import React, { useEffect } from 'react';
import moment from "moment"
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchGes, deleteGes } from "../redux/actions/gesaction";

function Manageges({ currentId, setCurrentId }) {
  const dispatch = useDispatch();

  const posts = useSelector((state) => state.gesReducer)
  console.log(posts)

  useEffect(() => {
    dispatch(fetchGes())
  }, [currentId, dispatch])


  return (
    <div>
      {!posts.length ? (
        !posts.length == 0 ? <div><h1 style={{ textAlign: "center", paddingTop: "15%" }}>No ges Feedback</h1> </div> :
          <div className="loading" style={{ paddingBottom: "30px" }}></div>)

        : (
          <div className='container containerWrapper'>
            <div className='title'>GES Feedback </div>
            <div className='bookContainer'>
              {posts.sort((a, b) => moment(new Date(b.id)) - moment(new Date(a.id))).map((post, i) => (

                <div className='cardWrapper' key={i}>
                  <div className='image'>
                    <img src={post.image} alt="" />
                  </div>

                  <div className='wrapper'>
                    <div className='name'>{post.name}</div>
                    <div className='location'>{post.location} </div>
                    <div className='testimony'>{post.testimony} </div>

                    <div className='action'>
                      <div className='del-btn'><span onClick={() => dispatch(deleteGes(post._id))} className="delete-btn">Delete</span></div>
                      <NavLink to="/ges_form">
                        <button onClick={() => setCurrentId(post._id)} className="edit">Edit</button>
                      </NavLink>

                    </div>
                  </div>
                </div>
              ))}

            </div>

          </div>



        )}

    </div>
  )
}

export default Manageges