import React from 'react';
import { NavLink } from 'react-router-dom';
import "./dashboard.css"

function Dashboard() {
    return (
        <div className='dashboard container'>
            <div className='row'>
                <div className='col-lg-3 col-md-2 col-sm-12'></div>
                <div className='col-lg-6 col-md-8 col-sm-12'>

                    <div className='dashboard-page'>
                        <div className='container'>
                            <div className='title'>Welcome !!!</div>
                            <div className='row dashboardrow'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/resources_form" className="dashboard-nav">
                                        <div className='dashboard-content dashboardResources'>
                                            Add Gwop Resources
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/resources_feedback" className="dashboard-nav">
                                        <div className='dashboard-content dashboardResources2'>
                                            View Gwop Resources
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/gwop_form" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent'>Add Gwop feedback
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/gwop_feedback" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent2'>View Gwop Feedbacks
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>

                                    <NavLink to="/ges_form" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent3'>Add GES Testimony
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/ges_testimony" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent4'>View Ges Testimony
                                        </div></NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/add_blog" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent5'>Add Blog
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/blogs" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent6'>View Blogs
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/add_event" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent7'>Add Event
                                        </div>
                                    </NavLink>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <NavLink to="/events" className="dashboard-nav">
                                        <div className='dashboard-content dashboardContent8'>View Events
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-2 col-sm-12'></div>
            </div>
        </div>
    )
}

export default Dashboard