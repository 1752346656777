export const END_LOADING = "END_LOADING"
export const START_LOADING = "START_LOADING"

export const CREATEGWOP = 'CREATEGWOP';
export const UPDATEGWOP = "UPDATEGWOP";
export const DELETEGWOP = "DELETEGWOP";
export const FETCH_ALLGWOP = "FETCH_ALLGWOP";

//ges
export const CREATE = 'CREATE';
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";

// blog
export const CREATEBLOG = "CREATEBLOG";
export const UPDATEBLOG = "UPDATEBLOG";
export const DELETEBLOG = "DELETEBLOG";
export const FETCH_ALLBLOG = "FETCH_ALLBLOG";

//events
export const CREATEEvent = 'CREATEEvent';
export const UPDATEEvent = "UPDATEEvent";
export const DELETEEvent = "DELETEEvent";
export const FETCH_ALLEvent = "FETCH_ALLEvent";

// gwopResources
export const CREATEGWOPRESOURCES = "CREATEGWOPRESOURCES";
export const FETCHGWOPRESOURCES = "FETCHGWOPRESOURCES"
export const UPDATEGWOPRESOURCES = "UPDATEGWOPRESOURCES"
export const DELETEGWOPRESOURCES = "DELETEGWOPRESOURCES"
export const FETCHSINGLEGWOPRESOURCES = "FETCHSINGLEGWOPRESOURCES"



export const AUTH = 'AUTH';
export const LOGOUT = "LOGOUT"