import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { gesReducer } from "./reducers/gesReducer";
import { gwopReducer } from "./reducers/gwopReducer";
import { alertsReducer } from "./reducers/alertReducer";
import { blogReducer } from "./reducers/blogReducer";
import { eventReducer } from "./reducers/eventReducer";
import { gwopResourcesReducer } from "./reducers/gwopResources";

const composeEnhancers = composeWithDevTools({
    gesReducer,
    alertsReducer,
    gwopReducer,
    blogReducer,
    eventReducer,
    gwopResourcesReducer
});
const rootReducer = combineReducers({
    gesReducer,
    alertsReducer,
    gwopReducer,
    blogReducer,
    eventReducer,
    gwopResourcesReducer
})
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)

export default store